import React, { useEffect } from "react";
import { useRive, Layout, Fit, useStateMachineInput, decodeFont } from "@rive-app/react-canvas";

const RiveRisk = ({ percentage, Number, autoPlay }) => {
  const { rive, RiveComponent } = useRive({
    src: "/Rive/oldgraphs.riv",
    artboard: "Account Status",
    stateMachines: "Graph SM",
    layout: new Layout({
      fit: Fit.ScaleDown,
    }),
    autoplay: true, // Autoplay managed dynamically
    assetLoader: (asset, bytes) => {
      if (asset.isFont) {
        fetch("/Rive/Inter-594377.ttf")
          .then((res) => res.arrayBuffer())
          .then((buffer) => {
            decodeFont(new Uint8Array(buffer)).then((font) => {
              asset.setFont(font);
              font.unref();
            });
          });
        return true;
      }
      return false;
    },
  });

  const numberInput = useStateMachineInput(rive, "Graph SM", "Number");

  // Handle autoplay dynamically
  // useEffect(() => {
  //   if (rive) {
  //     if (autoPlay) {
  //       rive.play(); // Start the animation
  //     } else {
  //       rive.pause(); // Pause the animation
  //     }
  //   }
  // }, [rive, autoPlay]);

  // Update state machine input when `Number` changes
  useEffect(() => {
    if (numberInput) {
      numberInput.value = Number;
    }
  }, [Number, numberInput]);

  // Update text values in the Rive file dynamically
  useEffect(() => {
    if (rive) {
      rive.setTextRunValue("Percentage Run", `${percentage}`);
    }
  }, [rive, percentage]);

  return (
    <div className="gaugeChart__InnerContainer"
    //  style={{ width: "100%", height: "100%" }}
    >
      <RiveComponent />
    </div >
  );
};

export default RiveRisk;