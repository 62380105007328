import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../ASSETS/logo.svg";
// import HomeIcon from "../ASSETS/Home.svg";
import Sidebar__2ndIcon from "../ASSETS/Sidebar__2ndIcon.svg";
import Sidebar__3rdIcon from "../ASSETS/Sidebar__3rdIcon.svg";
import Sidebar__4thIcon from "../ASSETS/Sidebar__4thIcon.svg";
import AdminNewPageIcon from "../ASSETS/AdminNewPageIcon.svg";
// import InboxIcon from "../ASSETS/Inbox.svg";
// import WalletIcon from "../ASSETS/Wallet.svg";
import SettingsIcon from "../ASSETS/Settings.svg";
import LogoutIcon from "../ASSETS/Logout.svg";
import "../CSS/sidebar.css";

export default function AdminSidebar({ adminSelectedPage, setAdminSelectedPage }) {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setAdminSelectedPage("PoolManager");
        break;
      case "/InvestorManager":
        setAdminSelectedPage("InvestorManager");
        break;
      case "/Inbox&Blog":
        setAdminSelectedPage("Inbox&Blog");
        break;
      case "/Settings":
        setAdminSelectedPage("Settings");
        break;
      default:
        setAdminSelectedPage("");
    }
  }, [location, adminSelectedPage]);

  return (
    <div className="sidebar">
      <div className="logoNPages">
        <img className="logo" src={logo} alt="Stack's Logo" />

        <div className="LinksCont">

          <Link className="Link" to="/">
            <li
              className={
                adminSelectedPage === "PoolManager"
                  ? "sidebar__item--selectedPage sidebar__item"
                  : "sidebar__item"
              }
            >
              <img src={Sidebar__2ndIcon} alt="InboxIcon" />
              <p>Pool Manager</p>
            </li>
          </Link>
          <Link className="Link" to="/PoolDashboard">
            <li
              className={
                adminSelectedPage === "PoolDashboard"
                  ? "sidebar__item--selectedPage sidebar__item"
                  : "sidebar__item"
              }
            >
              <img src={AdminNewPageIcon} alt="InboxIcon" />
              <p>Pool Dashboard</p>
            </li>
          </Link>

          <Link className="Link" to="/InvestorManager">
            <li
              className={
                adminSelectedPage === "InvestorManager"
                  ? "sidebar__item--selectedPage sidebar__item"
                  : "sidebar__item"
              }
            >
              <img src={Sidebar__3rdIcon} alt="WalletIcon" />
              <p>Investor Manager</p>
            </li>
          </Link>
          <Link className="Link" to="/Inbox&Blog">
            <li
              className={
                adminSelectedPage === "Inbox&Blog"
                  ? "sidebar__item--selectedPage sidebar__item"
                  : "sidebar__item"
              }
            >
              <img src={Sidebar__4thIcon} alt="WalletIcon" />
              <p>Inbox & Blog</p>
            </li>
          </Link>

          <Link className="Link" to="/Settings">
            <li
              className={
                adminSelectedPage === "Settings"
                  ? "sidebar__item--selectedPage sidebar__item"
                  : "sidebar__item"
              }
            >
              <img src={SettingsIcon} alt="SettingsIcon" />
              <p>Settings</p>
            </li>
          </Link>
        </div>
      </div>
      <Link
        to="/Login"
        className="bottomRow Link"
        onClick={() => {
          sessionStorage.clear();
        }}
      >
        <img src={LogoutIcon} alt="Logout" />
        <p>Logout</p>
      </Link>
    </div>
  );
}