import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./CSS/NotificationDetail.css";
import back from "./ASSETS/ArrowLeft.svg";
import Trash from "./ASSETS/Trash.svg";
// import Reply from "./ASSETS/Reply.svg";
import ReplyWhite from "./ASSETS/Reply--white.svg";
// import Send from "./ASSETS/Send.svg";
import AddFile from "./ASSETS/AddFile.svg";
import UserProfile from './Funcs/UserProfile';
export default function NotificationDetail({ notif, id, selectedPage, setselectedPage,
  notifDetailShown,
  setNotifDetailShown,
  type,
  subject,
  invoiceNumber,
  email,
  message,
  dueDate,
  to,
  amount,
  dateReceived,
  isread = false,
  accepted,
  isNewMessage = false,
  from,
  user_name,
  user_id,
  receipt

}) {
  // const invoiceTo = 1; // AA TEST
  // const { id } = useParams();
  const storedUserId = UserProfile.getId();
  const notification = notif || { // no need for notif GO BACK TO
    id,
    type,
    subject,
    invoiceNumber,
    email,
    message,
    dueDate,
    amount,
    dateReceived,
    isread,
    accepted,
    isNewMessage,
    from,
    to,
    user_name,
    user_id,
    receipt
  };
  // console.log(notification);
  const [replyShown, setReplyShown] = useState(false);
  const textAreaRef = useRef(null);
  const maxLines = 17;
  const lineHeight = 20; // Adjust based on your textarea's line height
  // console.log(to);
  // console.log(from);
  // console.log(invoiceNumber);
  // useEffect(() => {
  //   console.log("====================================");
  //   console.log("to: " + to);
  //   console.log("invoiceTo: " + invoiceTo);
  // }, [])
  console.log(notification);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let dateMonth = "";
  if (dueDate) {
    const [month, year] = dueDate.split("-");
    dateMonth = `${monthNames[parseInt(month, 10) - 1]} ${year}`;
  }
  // Start -- the reply
  const handleInputChange = (setter) => (event) => {
    const { value } = event.target;
    setter(value);
    // console.log(value); // AA TEST
  };
  const [replySubject, setreplySubject] = useState("");
  const [replyMessage, setreplyMessage] = useState("");
  const [XXX, setXXX] = useState(to);
  // End -- the reply

  const textAreaAdjust = () => {
    const element = textAreaRef.current;
    const maxHeight = maxLines * lineHeight;
    element.style.height = "1px";
    const newHeight = 25 + element.scrollHeight;
    element.style.height = newHeight > maxHeight ? `${maxHeight}px` : `${newHeight}px`;
    element.style.overflow = newHeight > maxHeight ? 'auto' : 'hidden';
  };

  if (!notification) {
    return <h1>Error404 Can't Load Content</h1>;
  }

  const handleDeclineInvoice = () => {
    fetch("https://admin.stackinvestment.net/stackInvest-BackEnd/acceptInvoice.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "invoice_id": id,
        "accepted": 0,
        "user_type": "admin"
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log("Invoice Declined:", data);
        window.location.reload();
      })
      .catch(error => {
        console.error("Error Declining Invoice:", error);
        window.location.reload();
      });

  }
  const handleAcceptInvoice = () => {
    fetch("https://admin.stackinvestment.net/stackInvest-BackEnd/acceptInvoice.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "invoice_id": id,
        "accepted": 1,
        "user_type": "admin"
      })
    })

      .then(response => response.json())
      .then(data => {
        console.log("Invoice Accepted:", data);
        window.location.reload();
      })
      .catch(error => {
        console.error("Error Accepting Invoice:", error);
        window.location.reload();
      });
  }
  return <>
    <div className="notificationDetailHero">
      <div className="backNTrash">
        {
          replyShown ? <img className="goBack" src={back} alt="Back" title="Go Back" onClick={() => { setReplyShown(!replyShown) }} /> : <img className="goBack" src={back} alt="Back" title="Go Back" onClick={() => { setNotifDetailShown(!notifDetailShown) }} />
        }


        {replyShown ? null : type === "Email" ? <img src={Trash} alt="Delete Email" title="Delete Email" /> : <img src={Trash} alt="Delete Receipt" title="Delete Receipt" />
        }

      </div>
      <div className="hero__content" style={{ borderColor: replyShown ? "#2c3e6c" : null, boxShadow: replyShown ? "-2px 2px 8px -1px #2c3e6c" : null }}>
        {
          type === "Email" ? <>
            <h1 className="NotifD__heading">{subject}</h1>
            <div className="NotifD__header--sub">
              <div className="flex">
                <h2>{from}</h2>
                {/* <h4>{` <${notification.email}>`}</h4> IBRA not needed GO BACK TO  */}
              </div>
              <div className="flex">
                <h4 className="DateReceived">{`${dateReceived} `}</h4>
              </div>
            </div>
            <div className="msgContainer">

              {
                replyShown ? <p className="message">{message.slice(0, 110)}...</p> : <p className="message">{message}</p>
              }
            </div>

            {
              replyShown ? null : <button
                className="CTA CTA--reply"
                onClick={() => {
                  console.log("Happy Replies Only"); // AA TEST
                  setReplyShown(true);
                }}
              >
                <img src={ReplyWhite} alt="reply"
                />
                Reply
              </button>
            }

          </> :
            type === "Invoice" ? <>
              <h1 className="NotifD__heading">{receipt} Request</h1>
              <div className="NotifD__header--sub">
                <div className="flex">
                  <h2>{from}</h2>
                </div>
                <div className="flex">
                  <h4 className="DateReceived">{`${dateReceived} `}</h4>
                </div>
              </div>
              <div className="invoiceContent">
                <div className="flex">
                  <h4>Request Number:</h4>
                  <p> {invoiceNumber}</p>
                </div>
                <div className="flex">
                  <h4>Request Date:</h4>
                  <p> {dateReceived}</p>
                </div>
                <div className="flex">
                  <h4>Request Type:</h4>
                  <p> {receipt}</p>
                </div>
                <div className="flex">
                  <h4>Total Amount Due:</h4>
                  <p> ${amount}</p>
                </div>
                <div className="flex">
                  {to === "You" ? 
                    (accepted !== null ? 
                      (accepted === 1 ? <><h4>Status:</h4> <p>Accepted</p></> : 
                      accepted === 0 ? <><h4>Status:</h4><p>Declined</p></> : 
                      <><h4>Status:</h4><p>Pending</p></>) 
                      : null)
                    : to === user_name ?
                      (accepted === 1 ? <><h4>Status:</h4> <p>Accepted</p></> : 
                      accepted === 0 ? <><h4>Status:</h4><p>Declined</p></> : 
                      accepted === null ? <><h4>Status:</h4><p>Pending</p></> : 
                      <p>Opps.. Can't view Status at the moment</p>) 
                    : null}
                </div>
              </div>


              {to === user_name ? null : 
                to === "You" ? 
                  (accepted !== null ? null : 
                    accepted === 1 ? 
                      <div className="It-Is-Accepted">
                        <p className="CTA CTA--Invoice CTA--Invoice--Active">Accepted</p>
                        <p className="CTA CTA--Invoice CTA--nonActive">Decline</p>
                      </div> : 
                    accepted === 0 ? 
                      <div className="It-Is-Not-Accepted">
                        <p className="CTA CTA--Invoice CTA--nonActive">Accept</p>
                        <p className="CTA CTA--Invoice CTA--Invoice--Active">Declined</p>
                      </div> : 
                    accepted === null ? 
                      <div className="It-Is-Not-Accepted">
                        <button className="CTA CTA--Invoice" onClick={handleAcceptInvoice}>
                          Accept
                        </button>
                        <button className="CTA CTA--Invoice" onClick={handleDeclineInvoice}>
                          Decline
                        </button>
                      </div> : 
                    <h1>
                      Opsss.. You can't Perform Any Actions at the moment...
                    </h1>) : 
                null}

            </> : <>
              <h1 className="NotifD__heading">Profit Distribution of {dateMonth} </h1>
              <div className="NotifD__header--sub">
                <div className="flex">
                  <h2>{from}</h2>
                </div>
                <div className="flex">
                  <h4 className="DateReceived">{`${dateReceived} `}</h4>
                </div>
              </div>
              <div className="invoiceContent">
                <div className="flex">
                  <h4>Receipt Number:</h4>
                  <p> {invoiceNumber}</p>
                </div>
                <div className="flex">
                  <h4>Receipt Date:</h4>
                  <p> {dateReceived}</p>
                </div>
                <div className="flex">
                  <h4>Due Date:</h4>
                  <p> {dueDate}</p>
                </div>
                <div className="flex">
                  <h4>Total Amount Due:</h4>
                  <p> ${amount}</p>
                </div>

                <div className="flex">
                  {to === "You" ? null : to === user_name ?
                    <>
                      <h4>To:</h4>
                      <p> {user_name}</p>
                    </> : null
                  }
                </div>
                <div className="flex">

                  {to === "You" ? null : to === user_name ?
                    accepted === 1 ? <><h4>Status:</h4> <p>Accepted</p></> : accepted === 0 ? <><h4>Status:</h4><p>Declined</p></> : accepted === null ? <><h4>Status:</h4><p>Pending</p></> : <p>Opps.. Can't view Status at the moment</p> : null}
                </div>
              </div>


              {to === user_name ? null : to === "You" ?
                accepted === 1 ?
                  <div className="It-Is-Accepted">
                    <p className="CTA CTA--Invoice CTA--Invoice--Active">Accepted</p>
                    <p className="CTA CTA--Invoice 
                CTA--nonActive">Decline</p>
                  </div>
                  : accepted === 0 ?
                    <div className="It-Is-Not-Accepted">
                      <p className="CTA CTA--Invoice 
                  CTA--nonActive">Accept</p>
                      <p className="CTA CTA--Invoice 
                  CTA--Invoice--Active">Declined</p>
                    </div>
                    : accepted === null ?
                      <div className="It-Is-Not-Accepted">
                        <button className="CTA CTA--Invoice"
                          onClick={() => {
                            handleAcceptInvoice();
                          }}
                        >Accept</button>
                        <button className="CTA CTA--Invoice"
                          onClick={() => {
                            handleDeclineInvoice();
                          }}
                        >Decline</button>
                      </div>
                      : <h1>
                        Opsss.. You can't Perform Any Actions at the moment...
                      </h1> : null
              }

            </>

        }

      </div>
      {replyShown ? <div className="MessagePopUp--Reply">
        <div className="Popupcard">
          <textarea
            ref={textAreaRef}
            onKeyUp={textAreaAdjust}
            style={{ overflow: 'hidden', lineHeight: `${lineHeight}px`, resize: 'none' }}
            className="messageToBeSent NewMsgInput"
            type="text"
            placeholder="Write Your Message here..."
            value={replyMessage}
            onChange={handleInputChange(setreplyMessage)}
          />
          <div className="CTAContainer">
            <button
              className="CTA CTA--send"
              onClick={() => {
                setReplyShown(false);
                setNotifDetailShown(false);
                console.log("Message Sent"); // AA TEST
              }}
            >
              Send</button>
            <div className="CTAContainer--abs ClickHover">
              <div className="CTAContainer--rel" title="Add a File">
                <input type="file" className="absoluteFile--input ClickHover" placeholder="" />
                <img src={AddFile} className="absoluteFile--img ClickHover" alt="Add a File" />
              </div>
            </div>


            {/* NEEDS BACKEND, THIS IS A FORM, NOT VARIABLES */}
          </div>
        </div>
      </div> : <></>
        // </div> : <h1>No reply container</h1>  // AA TEST 
      }
    </div >



  </>
}
