import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./CSS/universal.css";
import LoadingScreen from "./AdminComp/LoadingScreen";
import InvestorPage from "./AdminComp/InvestorPage";
import PrivateRoute from './PrivateRoute.jsx';
// Function to simulate a delay for lazy loading
const delayedLazy = (importFunc, delay) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(importFunc());
    }, delay);
  });
};

// Lazy load the components with a delay of 1500ms (1.5 seconds)
const AdminPoolManager = lazy(() => delayedLazy(() => import("./AdminPoolManager"), 1500));
const PoolDashboard = lazy(() => delayedLazy(() => import("./PoolDashboard"), 1500));
const InvestorManager = lazy(() => delayedLazy(() => import("./InvestorManager"), 1500));

// const Wallet = lazy(() => delayedLazy(() => import("./Wallet"), 1500));
const Settings = lazy(() => delayedLazy(() => import("./Settings"), 1500));
const Inbox = lazy(() => delayedLazy(() => import("./Inbox"), 1500));
const Login = lazy(() => delayedLazy(() => import("./Login"), 1500));
// const Register = lazy(() => delayedLazy(() => import("./Register"), 1500));

function App() {
  const [adminSelectedPage, setAdminSelectedPage] = useState("PoolManager");

  document.addEventListener("keydown", (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "r") {
      window.scroll(0, 0);
    }
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route
            path="/"
            element={<PrivateRoute element={() => <AdminPoolManager adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />}
          />
          <Route
            path="/InvestorPage/:id"
            element={<PrivateRoute element={() => <InvestorPage adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />}
          />
          <Route
            path="/InvestorManager"
            element={<PrivateRoute element={() => <InvestorManager adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />}
          />
          <Route
            path="/PoolDashboard"
            element={<PrivateRoute element={() => <PoolDashboard adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />}
          />
          {/* <Route
            path="/PoolDashboard"
            element={<PoolDashboard adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />}
          /> */}

          {/* <Route path="/Wallet" element={<Wallet />} /> */}
          <Route path="/Settings" element={<PrivateRoute element={() => <Settings adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />} />

          <Route path="/Inbox&Blog" element={<PrivateRoute element={() => <Inbox adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage} />} />} />
          <Route path="/Login" element={<Login />} />

          {/* <Route path="/Login" element={<Login />} /> */}
          {/* <Route path="/Register" element={<Register />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
