import React, { useEffect, useState, useRef } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminNavbar from "./AdminNavbar";
import AAGraph from "./AAGraph";
import BalanceGraph from "./BalanceGraph";
import { useParams, useLocation } from "react-router-dom";
// import ProfitNLoss from "./ProfitNLoss";
import WonPercentageChart from "./WonPercentageChart";
// import AAPieChart from "./AAPieChart"; // AA GO BACK TO NOT NEEDED
// import GaugeComp from './GaugeComp'; // AA GO BACK TO NOT NEEDED
import "../CSS/Investor.css";
import EditIcon from "../ASSETS/Edit.svg";
import X from "../ASSETS/X.svg";
import UserProfile from '../Funcs/UserProfile';
import PDFViewer from './PDFViewer';
import RiveDoughnut from './RiveDoughnut';
import RiveRisk from './RiveRiskChart';
import RiveProfitAmountWeek from './RiveProfitAmountWeek';
// import RiveProfitAmountMonth from './RiveProfitAmountMonth';

export default function InvestorPage({ adminSelectedPage, setAdminSelectedPage }) {
  const [notif, setNotif] = useState([]);
  const [notifDetailShown, setNotifDetailShown] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedSection, setSelectedSection] = useState("Positions");
  const [isOrderDisplay, setIsOrderDisplay] = useState(true);
  const today = new Date();
  const [viewedWeek, setViewedWeek] = useState('All Weeks');
  const [viewedMonth, setViewedMonth] = useState(today.toLocaleString('default', { month: 'long' }));
  const [viewedYear, setViewedYear] = useState(today.getFullYear());

  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pool = queryParams.get('pool');

  const [notes, setNotes] = useState([]);




  useEffect(() => {
    // console.log(window.innerWidth); // AA TEST
    if (window.innerWidth < 800) {
      setSelectedSection("Positions");
      setIsOrderDisplay(false);
    }
  },
    []
    // [window.innerWidth] // AA TEST
  )

  // UseEffect to handle the Date picker "Apply" button click
  useEffect(() => {
    const monthInput = document.getElementById("monthInput");
    const yearInput = document.getElementById("yearInput");

    if (monthInput && yearInput) {
      // Set default selected month
      // monthInput.value = today.getMonth();

      // // Set default selected year
      // yearInput.value = today.getFullYear();
    }
  }, []);

  // Function to handle the Date picker "Apply" button click
  const handleApplyClick = () => {
    setViewedWeek(defaultWeekValue === "01" ? "Week 1"
      : defaultWeekValue === "02" ? "Week 2"
        : defaultWeekValue === "03" ? "Week 3"
          : defaultWeekValue === "04" ? "Week 4"
            : defaultWeekValue === "05" ? "Week 5"
              : defaultWeekValue === "00" ? "All Weeks"
                : defaultWeekValue === "All Weeks" ? "All Weeks"
                  : "Error Something went wrong2");
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    setViewedMonth(monthNames[parseInt(defaultMonthValue, 10) - 1] || "Error Something went wrong1");
    setViewedYear(defaultYearValue);

    setDatePickerVisible(false);
  };

  const [defaultWeekValue, setDefaultWeekValue] = useState('00');
  const [defaultMonthValue, setDefaultMonthValue] = useState(today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1);
  // console.log("defaultMonthValue", defaultMonthValue); // AA TEST
  const [defaultYearValue, setDefaultYearValue] = useState(today.getFullYear());

  useEffect(() => {
    const monthInput = document.getElementById("monthInput");
    const yearInput = document.getElementById("yearInput");
    if (monthInput && yearInput) {
      // Set default selected month
      monthInput.value = today.getMonth();
      // Set default selected year
      yearInput.value = today.getFullYear();
    }
  }, []);
  const dateToSend = `${defaultWeekValue}-${defaultMonthValue}-${defaultYearValue}`;
  // transaction history variables
  const [walletHistory, setWalletHistory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/transactions.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ // AA TEST GO BACK TO this is original code
            user_id: id,
          }),
        });
        const data = await response.json();
        setWalletHistory(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();

    // fetch("/JSON/Wallet.json") // AA TEST this json is for testnig only
    //   .then((response) => response.json())
    //   .then((data) => setWalletHistory(data))
    //   .catch((error) => console.log(error));

    // console.log("WalletHistory:"); // AA TEST
    // console.log(walletHistory); // AA TEST
  }, []);

  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/dashboard.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: id,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id, dateToSend]);

  const defaultData = {
    InvestorDetails: {
      name: '',
      mail: '',
      phone: '',
      starting_balance: '',
      profit_sharing: '',
      start_date: '',
    },
    PersonalEarnings: {
      shared_percentage: '',
      profits: '',
      bonus: '',
      total_balance: '',
      day_of_payout: '',
      status: '',
      note: ''
    },
    PoolDetails: {
      pool_id: '',
      size: '',
      roi: '',
      revenue: ''
    }
  };
  const data = dashboardData ?? defaultData;
  // console.log(data);
  const [positionsData, setPositionsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/posOr.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setPositionsData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const defaultPositions = {
    "Positions": [
      {
        "time_open": "",
        "symbol": "",
        "type": "",
        "volume": "",
        "price_open": "",
        "sl": "",
        "tp": "",
        "time_close": "",
        "price_close": "",
        "commission": "",
        "swap": "",
        "profit": ""
      }
    ],
    "Orders": [
      {
        "time_open": "",
        "symbol": "",
        "type": "",
        "volume": "",
        "price_open": "",
        "sl": "",
        "tp": "",
        "time_close": "",
        "state": "",
        "comment": ""
      }
    ]
  };
  const posData = positionsData ?? defaultPositions;
  const [resultsData, setResultsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/getResults.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setResultsData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const [revenueChart, setRevenueChart] = useState({
    point1: 0,
    point2: 0,
    point3: 0,
    point4: 0,
    autoPlay: false
  });
  const [chartKey, setChartKey] = useState(0); // Key to force remounting
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/revenueChart.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: id,
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
  
        // Map API data to revenueChart points
        const updatedChart = {
          point1: data[0]?.["Profit Amount"] || 0,
          point2: data[1]?.["Profit Amount"] || 0,
          point3: data[2]?.["Profit Amount"] || 0,
          point4: data[3]?.["Profit Amount"] || 0,
          autoPlay: false
        };
  
        setRevenueChart(updatedChart);
  
        // Increment the key to force remounting
        setChartKey((prevKey) => prevKey + 1);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchData();
  }, [id, pool, dateToSend]);

  const wonPercentage = [
    { name: 'Short Trades', 'Short %': parseFloat(resultsData?.short_trades_won_percentage?.replace('%', '') ?? 0) },
    { name: 'Long Trades', 'Long %': parseFloat(resultsData?.long_trades_won_percentage?.replace('%', '') ?? 0) },
  ];

  const [balanceData, setBalanceData] = useState([]);

  useEffect(() => {
    // Fetch balance data from the PHP script
    const fetchData = async () => {
      const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/balanceGraph.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: id,
          date: dateToSend,
        }),
      });
      const data = await response.json();
      setBalanceData(data);
    };

    fetchData();
  }, [id, dateToSend]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/fetchNotes.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setNotes(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const [riveDoughnutData, setRiveDoughnutData] = useState({});
  const [riveRiveRiskData, setRiveRiveRiskData] = useState({});
  const [RiveProfitAmountWeekData, setRiveProfitAmountWeekData] = useState({});
  const doughnutRef = useRef(null);
  const riskRef = useRef(null);
  const revenueWeekRef = useRef(null);

  useEffect(() => {
    if (data.InvestorDetails && data.PoolDetails) {
      setRiveDoughnutData({
        PercentagePool: 100 - Math.round(data.PersonalEarnings?.shared_percentage * 10) / 10,
        MoneyPool: data.PoolDetails?.size,
        PercentageShared: Math.round(data.PersonalEarnings?.shared_percentage * 10) / 10,
        MoneyShared: Math.round(data.PersonalEarnings?.starting_balance) !== 0 ? Math.round(data.PersonalEarnings?.starting_balance) : Math.round(data.InvestorDetails?.starting_balance),
        Number: 100 - Math.round(data.PersonalEarnings?.shared_percentage * 10) / 10,
        autoPlay: false, // Default to false
      });

      setRiveRiveRiskData({
        percentage: data.PersonalEarnings?.status || data.InvestorDetails?.status,
        Number: parseInt(data.PersonalEarnings?.status || data.InvestorDetails?.status),
        autoPlay: false, // Default to false
      });
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === doughnutRef.current) {
            setRiveDoughnutData((prev) => ({
              ...prev,
              autoPlay: entry.isIntersecting,
            }));
          } else if (entry.target === riskRef.current) {
            setRiveRiveRiskData((prev) => ({
              ...prev,
              autoPlay: entry.isIntersecting,
            }));
          } else if (entry.target === revenueWeekRef.current) {
            setRevenueChart((prev) => ({
              ...prev,
              autoPlay: entry.isIntersecting,
            }));
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    // Attach observer when the DOM elements are available
    if (doughnutRef.current) observer.observe(doughnutRef.current);
    if (riskRef.current) observer.observe(riskRef.current);
    if (revenueWeekRef.current) observer.observe(revenueWeekRef.current);

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);
  const { moneyIn, moneyOut } = walletHistory.reduce(
    (totals, item) => {
      if (item.type === "Withdraw") {
        totals.moneyOut += parseFloat(item.amount) || 0; // Add to moneyOut
      } else if (item.type === "Deposit" || item.type === "Profit") {
        totals.moneyIn += parseFloat(item.amount) || 0; // Add to moneyIn
      }
      return totals;
    },
    { moneyIn: 0, moneyOut: 0 } // Initial values
  );
  // console.log(riveDoughnutData); // AA TEST

  useEffect(() => {
    datePickerVisible !== false ?
      document.body.style.overflow = "hidden"
      : document.body.style.overflow = "auto"
  }, [datePickerVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.blurcard-mini')) {
        setDatePickerVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="DashHero">
      {datePickerVisible ? <div className="DashHero__blurBg">
        <div className="blurcard blurcard-mini">
          <img className="X" src={X} alt="Close Date Picker" onClick={() => { setDatePickerVisible(!datePickerVisible) }} />
          <h1>Date Picker</h1>
          <div className="flex">
            <div className="flex--col">
              <h2>Week</h2>
              <select value={defaultWeekValue} name="Week" id="weekInput" onChange={(e) => {
                setDefaultWeekValue(e.target.value)
              }
              }>

                <option value="00">All Weeks</option>
                <option value="01">Week 1</option>
                <option value="02">Week 2</option>
                <option value="03">Week 3</option>
                <option value="04">Week 4</option>
                <option value="05">Week 5</option>
              </select>
            </div>
            <div className="flex--col">
              <h2>Month</h2>
              {/* <input type="text" placeholder="Month" id="monthInput" /> */}
              <select value={defaultMonthValue} name="Month" id="monthInput" onChange={(e) => {
                setDefaultMonthValue(e.target.value)
              }
              }>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div className="flex--col">
              <h2>Year</h2>
              {/* <input type="text" placeholder="Year" id="yearInput" /> */}
              <select value={defaultYearValue} name="Year" id="yearInput" onChange={(e) => {
                setDefaultYearValue(e.target.value)
              }}
              >
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </select>
            </div>
          </div>
          <div className="CTAContainer">
            <button className="CTA" onClick={() => { handleApplyClick() }}>Apply</button>
          </div>
        </div>
      </div>
        : <></>}

      <AdminSidebar adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage}></AdminSidebar>
      <AdminNavbar
        notifDetailShown={notifDetailShown}
        setNotifDetailShown={setNotifDetailShown}
      >
      </AdminNavbar>
      <div className="hero__content">
        <h1 className="content__heading">Dashboard</h1>
        <div className="month">
          <p className="monthValue">{`${viewedWeek}, ${viewedMonth} ${viewedYear}`}</p>
          <img className="editIcon" title="Choose Date" src={EditIcon} alt="Choose Month" onClick={() => {
            setDatePickerVisible(!datePickerVisible);
          }}></img>
        </div>
        {/* Start of AllCards */}
        <div className="allCards">
          {/* Start of card 1 */}
          <div className="card">
            <h2 className="card__heading">Investors Detail's</h2>
            <div className="card__item">
              <p className="card__subTitle">Investor's Name:</p>
              <p className="card__variable">{data.InvestorDetails?.name ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Email:</p>
              <p className="card__variable">{data.InvestorDetails?.mail ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Phone number:</p>
              <p className="card__variable">{data.InvestorDetails?.phone ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Date of Start:</p>
              <p className="card__variable">{data.InvestorDetails?.start_date ?? ''}</p>
            </div>
            <div className="card__item">
                  <p className="card__subTitle">Starting Balance:</p>
                  <p className="card__variable">{`$${Math.round(data.PersonalEarnings?.starting_balance !== 0 ? data.PersonalEarnings?.starting_balance : data.InvestorDetails?.starting_balance)}`}</p>
                </div>
            <div className="card__item">
              <p className="card__subTitle">Profit Sharing:</p>
              <p className="card__variable"> {`${data.PersonalEarnings?.profit_sharing !== 0 ? data.PersonalEarnings?.profit_sharing : data.InvestorDetails?.profit_sharing !== null && data.InvestorDetails?.profit_sharing !== "null" ? data.InvestorDetails?.profit_sharing : 50}%`}
              </p>
            </div>

          </div>
          {/* End of card 1 */}
          {/* ------------------ */}
          {/* Start of card 2 */}
          <div className="card">
            <h2 className="card__heading">Personal Earnings</h2>
            <div className="card__item">
              <p className="card__subTitle">Shared Percentage:</p>
              <p className="card__variable">{`${Math.round(data.PersonalEarnings?.shared_percentage * 10) / 10}%`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Day of Payout:</p>
              <p className="card__variable">{data.PersonalEarnings?.day_of_payout ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Profit Amount:</p>
              <p className="card__variable">{`$${Math.round(data.PersonalEarnings?.profits * 10) / 10 ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Total balance from Date of Start:</p>
              <p className="card__variable">{`$${data.PersonalEarnings?.total_balance !== 0 ? data.PersonalEarnings?.total_balance : Math.round(data.InvestorDetails?.balance * 10) / 10}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Total Profit from Date of Start:</p>
              <p className="card__variable">{`$${Math.round(((data.PersonalEarnings?.total_balance ?? 0) - (data.PersonalEarnings?.starting_balance ?? 0) === 0
                ? ((Math.round((data.InvestorDetails?.balance ?? 0) * 10) / 10) - (data.InvestorDetails?.starting_balance ?? 0))
                : ((data.PersonalEarnings?.total_balance ?? 0) - (data.PersonalEarnings?.starting_balance ?? 0))) * 100) / 100}`}


              </p>
            </div>
          </div>
          {/* End of card 2 */}
          {/* ------------------ */}
          {/* Start of card 3 */}
          <div className="card">
            <h2 className="card__heading">Pool Details</h2>
            <div className="card__item">
              <p className="card__subTitle">Pool Size:</p>
              <p className="card__variable">{`$${data.PoolDetails?.size ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Revenue Earned:</p>
              <p className="card__variable">{`$${Math.round(data.PoolDetails?.revenue * 10) / 10 ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">ROI:</p>
              <p className="card__variable">{`${Math.round(data.PoolDetails?.roi * 10) / 10}%`}</p>
            </div>
          </div>
          {/* End of card 3 */}
          {/* ------------------ */}
          {/* Start of card 4 */}
          <div className="card">
            <h2 className="card__heading">Notes</h2>
            <div className="card__item card__item--input">
              <ul>
                {notes.length === 1 ? <p className="card__variable notes-items">
                  {notes.map((item, index) => {
                    return <p className="notes-items" key={index}>{item[`Note`]}</p>
                  })}
                </p> : notes.length > 1 ?

                  notes.map((item, index) => {
                    return <li className="notes-items notes-items--padded" key={index}>{item[`Note`]}</li>
                  })
                  : <p className="notes-items">Notes received from the Admin are shown here...</p>}
              </ul>
            </div>
          </div>
          {/* End of card 4 */}

          {/* End of cardContainer 3 n 4 */}
          {/* ------------------ */}
          {/* Start of card 6 */}
          <div className="card card--PosOrder">
            <div className="header">
              <h1 className={selectedSection === "Positions" ? 'content__heading-One selectedSection' : 'content__heading-One'} onClick={() => { setSelectedSection("Positions") }}
                style={isOrderDisplay ? {} : {}}>Positions</h1>

              {isOrderDisplay ?
                <h1 className={selectedSection === "Order" ? 'content__heading-Two selectedSection' : 'content__heading-Two'} onClick={() => { setSelectedSection("Order") }}>Order</h1> : <></>
              }
            </div>
            {
              selectedSection === "Positions" ?

                isOrderDisplay ? <div className="shadowOfTable">
                  <div className="row row-subTitle">
                    <p className="card__subTitle row--flexBaseSupaBig">Open Time</p>
                    <p className="card__subTitle row--flexBaseBig">Symbol</p>
                    <p className="card__subTitle row--flexBaseSmall">Type</p>
                    <p className="card__subTitle row--flexBaseBig">Volume</p>
                    <p className="card__subTitle row--flexBaseBig">Open Price</p>
                    <p className="card__subTitle row--flexBaseSmall">S/L</p>
                    <p className="card__subTitle row--flexBaseSmall">T/P</p>
                    <p className="card__subTitle row--flexBaseSupaBig">Close Time</p>
                    <p className="card__subTitle row--flexBaseBig">Close Price</p>
                    <p className="card__subTitle row--flexBaseBig">Profit</p>
                  </div>
                  <div className="card__item card__item--historyTable">
                    {/* --- */}

                    {posData.Positions.map((item, index) => (
                      <div className="row row--PosOrder" key={`rowId #${item.id}#${index}`}>
                        <p className="card__variable row--flexBaseSupaBig row--wrap">{item[`time_open`]}</p>
                        <p className="card__variable row--flexBaseBig">{item.symbol}</p>
                        <p className="card__variable row--flexBaseSmall">{item.type}</p>
                        <p className="card__variable row--flexBaseBig">{item.volume}</p>
                        <p className="card__variable row--flexBaseBig">{item[`price_open`]}</p>
                        <p className="card__variable row--flexBaseSmall">{item[`sl`]}</p>
                        <p className="card__variable row--flexBaseSmall">{item[`tp`]}</p>
                        <p className="card__variable row--flexBaseSupaBig">{item[`time_close`]}</p>
                        <p className="card__variable row--flexBaseBig">{item[`price_close`]}</p>
                        <p className="card__variable row--flexBaseBig">{item[`profit`]}</p>
                      </div>
                    ))}
                    {/* --- */}
                  </div>
                </div> : <div className="shadowOfTable">
                  <div className="row row-subTitle">
                    <p className="card__subTitle row--flexBaseBig">Symbol</p>
                    <p className="card__subTitle row--flexBaseSmall">Type</p>
                    <p className="card__subTitle row--flexBaseBig">Profit</p>
                  </div>
                  <div className="card__item card__item--historyTable">
                    {/* --- */}

                    {posData.Positions.map((item) => (
                      <div className="row row--PosOrder" key={item.id}>
                        <p className="card__variable row--flexBaseBig">{item.symbol}</p>
                        <p className="card__variable row--flexBaseSmall">{item.type}</p>
                        <p className="card__variable row--flexBaseBig">{item[`profit`]}</p>
                      </div>
                    ))}
                    {/* --- */}
                  </div>
                </div>
                : selectedSection === "Order" ? <div className="shadowOfTable">
                  <div className="row row-subTitle">
                    <p className="card__subTitle row--flexBaseSupaBig">Open Time</p>
                    <p className="card__subTitle row--flexBaseBig">Symbol</p>
                    <p className="card__subTitle row--flexBaseSmall">Type</p>
                    <p className="card__subTitle row--flexBaseBig">Volume</p>
                    <p className="card__subTitle row--flexBaseBig">Open Price</p>
                    <p className="card__subTitle row--flexBaseSmall">S/L</p>
                    <p className="card__subTitle row--flexBaseSmall">T/P</p>
                    <p className="card__subTitle row--flexBaseSupaBig">Close Time</p>
                    <p className="card__subTitle row--flexBaseBig">State</p>
                    <p className="card__subTitle row--flexBaseBig">Comment</p>
                  </div>
                  <div className="card__item card__item--historyTable">
                    {/* --- */}

                    {posData.Orders.map((item) => (
                      <div className="row row--PosOrder" key={item.id}>
                        <p className="card__variable row--flexBaseSupaBig row--wrap">{item[`time_open`]}</p>
                        <p className="card__variable row--flexBaseBig">{item.symbol}</p>
                        <p className="card__variable row--flexBaseSmall">{item.type}</p>
                        <p className="card__variable row--flexBaseBig">{item.volume}</p>
                        <p className="card__variable row--flexBaseBig">{item[`price_open`]}</p>
                        <p className="card__variable row--flexBaseSmall">{item[`sl`]}</p>
                        <p className="card__variable row--flexBaseSmall">{item[`tp`]}</p>
                        <p className="card__variable row--flexBaseSupaBig">{item[`time_close`]}</p>
                        <p className="card__variable row--flexBaseBig">{item[`state`]}</p>
                        <p className="card__variable row--flexBaseBig">{item[`comment`]}</p>
                      </div>
                    ))}
                    {/* --- */}
                  </div>
                </div> : <h1> Can't display content - Error in "selectedSection" </h1>
            }

          </div>
          {/* End of card 6 */}
          {/* ------------------ */}
          {/* Start of card 7 */}
          <div className="card card--Results card--PosOrder">
            <h1 className="card__heading">Results</h1>
            {
              <div className="shadowOfTable shadowOfTable--Results">
                <div className="row row-subTitle">
                  <p className="card__subTitle row--flexBaseSupaBig">
                    Title
                  </p>
                  <p className="card__subTitle row--flexBaseSupaBig">
                    Value
                  </p>
                </div>
                <div className="card__item card__item--historyTable">

                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Total Net Profit:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`total_net_profit`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Short Trades Won %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`short_trades_won_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Short Trades Won:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`short_trades_won_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Long Trades Won %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`long_trades_won_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Long Trades Won:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`long_trades_won_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Total Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`total_trades`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Profit Trades %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`profit_trades_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Profit Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`profit_trades_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Loss Trades %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`loss_trades_percentage`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Loss Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`loss_trades_value`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Larget Profit Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`largest_profit_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Largest Loss Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`largest_loss_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Average Profit Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`average_profit_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Average Loss Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`average_loss_trade`]}
                    </p>
                  </div>




                  {/* --- */}
                </div>
              </div>
              // : <h1> Can't display content - Error in "selectedSection" </h1>
            }

          </div>
          {/* End of card 7 */}
          {/* ------------------ */}
        </div>
        {/* End of AllCards */}
      </div >
      {/* end of hero__content */}
      {/* ================= */}
      <div className="TwoChart">
        <div className="PieChart" ref={doughnutRef}>
          <h1 className="Chart__heading">Shared Percentage</h1>
          <RiveDoughnut {...riveDoughnutData} />
        </div>
        {/* ------ */}
        <div className="GaugeChart" ref={riskRef}>
          <h1 className="Chart__heading">Account Status</h1>
          <RiveRisk {...riveRiveRiskData}></RiveRisk>
        </div>
      </div>
      {/* ================ */}
      {/* <div className="RevenueGraph" ref={revenueWeekRef}>
        <h1 className="content__heading">Profit Amount / Week</h1>
        <RiveProfitAmountWeek
          {...revenueChart}
        />
      </div> */}
      {/* ================= */}
      {/* <div className="BalanceGraph">
        <h1 className="content__heading">Profit Amount / Month</h1>
        <RiveProfitAmountMonth />
      </div> */}
      {/* ================ */}
      {/*  <div className="ProfitNLoss">
        <h1 className="content__heading">Profit & Loss Analysis</h1>
        <ProfitNLoss></ProfitNLoss>
      </div> */}
      {/* ================ */}
      {/* <div className="ProfitNLoss PercentageWon">
        <h1 className="content__heading">Percentage Won</h1>
        {/* <WonPercentageChart data={wonPercentage} /> */}
      {/*</div> */} 
      {/* ================ */}
      <div className="TransactionSection">

        <h1 className="content__heading Basic-Fade">Account Statement</h1>
        {/* Start of card 6 */}
        <div className="card card--PosOrder card--PosOrder--NoDisappear ">
          <div className="header">
          </div>
          {
            <div className="shadowOfTable">
              <div className="row row-subTitle">
                <p className="card__subTitle row--flexBaseBig">Date</p>
                {/* <p className="card__subTitle row--flexBaseSupaBig">Time</p> */}
                <p className="card__subTitle row--flexBaseSupaBig">Transactions</p>
                <p className="card__subTitle row--flexBaseBig">Money Out</p>
                <p className="card__subTitle row--flexBaseBig">Money In</p>
                {/* <p className="card__subTitle row--flexBaseBig">Money</p> */}
                <p className="card__subTitle row--flexBaseBig">Status</p>
                <p className="card__subTitle row--flexBaseSupaBig">Transaction Number</p>
                {/* <p className="card__subTitle row--flexBaseBig">Balance</p> */}
              </div>
              <div className="card__item card__item--historyTable">
                {/* --- */}

                {walletHistory.map((item) => {
                  const [date, time] = item.date.split(" "); // Split date and time

                  return (
                    <div className="row row--PosOrder" key={item.id}>
                      <p className="card__variable row--flexBaseBig ">{date}</p> {/* Date */}
                      {/* <p className="card__variable row--flexBaseSupaBig row--wrap">{time}</p> Time */}
                      <p className="card__variable row--flexBaseSupaBig">{item.type}</p>
                      <p className="card__variable row--flexBaseBig" style={{ color: "#dc463d" }}>
                        {item.type === "Withdraw" ? item.amount : "-"} {/* Money Out */}
                      </p>
                      {/* AA TEST ABOVE "null" should not be string */}
                      <p className="card__variable row--flexBaseSmall" style={{ color: "#3ec143" }}>
                        {item.type === "Deposit" || item.type === "Profit" ? item.amount : "-"} {/* Money In */}
                      </p>

                      {/* Status with conditional color */}
                      {item.status === "Pending" ? (
                        <p className="card__variable row--flexBaseBig" style={{ color: "#f1a23a" }}>{item.status}</p>
                      ) : item.status === "Accepted" ? (
                        <p className="card__variable row--flexBaseBig" style={{ color: "#3ec143" }}>{item.status}</p>
                      ) : item.status === "Declined" ? (
                        <p className="card__variable row--flexBaseBig" style={{ color: "#dc463d" }}>{item.status}</p>
                      ) : (
                        <p style={{ textWrap: "wrap", textAlign: "center" }}>Oops... Couldn't load status</p>
                      )}

                      <p className="card__variable row--flexBaseSupaBig">{item.invoice_number}</p>

                      {/* <p className="card__variable row--flexBaseBig">Balance Value</p> */}

                    </div>
                  );
                })}


                {/* --- */}
              </div>
              <div className="row row-subTitle row--Bottom">
                <p className="card__subTitle row--flexBaseBig"></p>
                <p className="card__subTitle row--flexBaseSupaBig">Closing Balance</p>
                <p className="card__subTitle row--flexBaseBig" style={{ color: "#dc463d" }}>${moneyOut}</p>
                <p className="card__subTitle row--flexBaseBig" style={{ color: "#3ec143" }}>${moneyIn}</p>
                <p className="card__subTitle row--flexBaseBig">Balance: ${data.InvestorDetails?.starting_balance}</p>
                <p className="card__subTitle row--flexBaseSupaBig">Total Balance: ${Math.round(data.InvestorDetails?.balance)}</p>
                {/* <p className="card__subTitle row--flexBaseBig">Balance: ${data.InvestorDetails?.starting_balance}</p> */}
              </div>
            </div>
          }

        </div>
        {/* End of card 6 */}


      </div>
      {/* <div className="ProfitNLoss PercentageWon">
        <h1 className="content__heading">Trade Report</h1>
        <PDFViewer pdfUrl={pdfUrl} />
      </div> */}
    </div >
  );
}
