import React, { useEffect, useState } from "react";
import { useRive, Layout, Fit, useStateMachineInput, decodeFont } from "@rive-app/react-canvas";

const RiveDoughnut = ({ PercentagePool, MoneyPool, PercentageShared, MoneyShared, Number, autoPlay }) => {
  const [key, setKey] = useState(0); // A state to force remounting

  useEffect(() => {
    if (autoPlay) {
      // Update the key to remount when autoPlay changes
      setKey((prevKey) => prevKey + 1);
    }
  }, [autoPlay]);

  return autoPlay ? (
    <RiveDoughnutRenderer
      key={key} // Force re-render by changing key
      PercentagePool={PercentagePool}
      MoneyPool={MoneyPool}
      PercentageShared={PercentageShared}
      MoneyShared={MoneyShared}
      Number={Number}
    />
  ) : null;
};

const RiveDoughnutRenderer = ({ PercentagePool, MoneyPool, PercentageShared, MoneyShared, Number }) => {
  const { rive, RiveComponent } = useRive({
    src: "/Rive/oldgraphs.riv",
    artboard: "Graph 1 v2",
    stateMachines: "Graph SM",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: true, // Autoplay when mounted
    assetLoader: (asset, bytes) => {
      if (asset.isFont) {
        fetch("/Rive/Inter-594377.ttf")
          .then((res) => res.arrayBuffer())
          .then((buffer) => {
            decodeFont(new Uint8Array(buffer)).then((font) => {
              asset.setFont(font);
              font.unref();
            });
          });
        return true;
      }
      return false;
    },
  });

  const numberInput = useStateMachineInput(rive, "Graph SM", "Number");

  // Update state machine input when `Number` changes
  useEffect(() => {
    if (numberInput) {
      numberInput.value = Number;
    }
  }, [Number, numberInput]);

  // Update text values in the Rive file dynamically
  useEffect(() => {
    if (rive) {
      rive.setTextRunValue("MoneyShared", `${MoneyShared}`);
      rive.setTextRunValue("PercentageShared", `${PercentageShared}%`);
      rive.setTextRunValue("MoneyPool", `${MoneyPool}`);
      rive.setTextRunValue("PercentagePool", `${PercentagePool}%`);
    }
  }, [rive, MoneyShared, PercentageShared, MoneyPool, PercentagePool]);

  return (
    <div className="PieChart__InnerContainer" >
      <RiveComponent />
    </div>
  );
};

export default RiveDoughnut;